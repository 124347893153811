import { Link } from 'react-router-dom';
import { PlayCircleIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import useContent from '../../../hooks/useContent.tsx';
import useContentAnalytics from '../../../hooks/useContentAnalytics.tsx';
import { useCreatorConfig } from '../../../providers/ConfigProvider';
import useUserAccess from '../../../hooks/useUserAccess.tsx';
import { orderBy } from 'lodash';
import { CourseProductSection } from '../../../types/course-product.ts';
import classNames from '../../../utils/classNames.ts';
import Pill from '../../_base/Pill';
import Button from '../../_base/Button';
import Loader from '../../_base/Loader';
import SecondaryText from '../../_base/SecondaryText';
import PrimaryText from '../../_base/PrimaryText';

const ResumeWatchingCard = ({ className, title, ...props }: { className?: string; title?: string }) => {
    const { content } = useCreatorConfig();
    const { userAccess, isLoading: isLoadingAccess } = useUserAccess();
    const accessibleContent = content?.filter(({ id }) => userAccess?.find(({ contentId }) => contentId === id));

    const {
        latestWatchedLesson: latestWatchedLessonAllCourses,
        // courseProgressPercentage,
        // totalLessons,
        // isLoading: isLoadingCourseAnalytics,
    } = useContentAnalytics();

    const courseId = latestWatchedLessonAllCourses?.analytics?.productId ?? accessibleContent?.[0]?.id?.toString();

    const {
        latestWatchedLesson,
        latestWatchedLessonPosition,
        // courseProgressPercentage,
        // totalLessons,
        isLoading: isLoadingCourseAnalytics,
    } = useContentAnalytics({ productId: courseId });

    const { data: course, isLoading: isLoadingCourse } = useContent({
        type: 'course',
        productId: courseId,
    });

    const modules = orderBy(course?.sections, ['order'], ['asc']) as CourseProductSection[];

    return (
        <div className={classNames('flex-1 lg:max-w-[50%]', className)}>
            {title && (
                <SecondaryText inlineBlock className="font-semibold mb-2 text-base">
                    {title}
                </SecondaryText>
            )}
            {(isLoadingAccess || isLoadingCourse || isLoadingCourseAnalytics) && (
                <div className="aspect-video rounded-md overflow-hidden relative flex items-center justify-center">
                    <Loader />
                </div>
            )}
            {!isLoadingCourse && !isLoadingCourseAnalytics && (
                <Link
                    to={
                        latestWatchedLesson
                            ? `/courses/${courseId}/modules/${latestWatchedLesson?.section?.id}/lessons/${latestWatchedLesson?.id}`
                            : `/courses/${courseId}/modules/${modules?.[0]?.id}`
                    }
                    {...props}>
                    <div className="relative">
                        {latestWatchedLesson && (
                            <div className="aspect-video rounded-md overflow-hidden relative border-0">
                                <img
                                    src={
                                        latestWatchedLesson?.assets?.find(
                                            ({ asset_type }) => asset_type?.name === 'Secure Video',
                                        )?.thumbnail
                                    }
                                    className="w-full object-cover object-center absolute"
                                />
                                <PrimaryText
                                    isOnThumbnail
                                    className="absolute  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                    <PlayCircleIcon className="h-12 w-12" />
                                </PrimaryText>
                            </div>
                        )}
                        <Button isPrimary size="xs" className="absolute top-4 left-4">
                            {(latestWatchedLessonPosition || 0) > 0 ? 'Continue watching' : 'Start watching'}
                        </Button>
                        <div className="absolute bottom-4 left-4 right-4 flex items-end justify-between gap-4">
                            <div>
                                <PrimaryText isOnThumbnail block className="text-base lg:text-xl font-bold">
                                    {latestWatchedLesson?.name}
                                </PrimaryText>
                                <SecondaryText isOnThumbnail block className="text-base lg:text-base">
                                    {latestWatchedLesson?.section?.name}
                                </SecondaryText>
                            </div>
                            <Pill
                                label={
                                    latestWatchedLesson?.analytics?.lastVideoPlayTimeSeconds
                                        ? moment
                                              .utc(
                                                  (latestWatchedLesson?.analytics?.lastVideoPlayTimeSeconds || 0) *
                                                      1000,
                                              )
                                              .format('HH:mm:ss')
                                        : '00:00:00'
                                }
                            />
                        </div>
                    </div>
                </Link>
            )}
        </div>
    );
};

export default ResumeWatchingCard;
