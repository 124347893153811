import { Auth } from 'aws-amplify';
import camelize from './camelize.ts';

const addUrlSubstitutions = async (url: string) => {
    const { attributes } = await Auth.currentAuthenticatedUser();
    const userAttributes = camelize(attributes);


    return url
        .replace('{USER_ID}', userAttributes?.sub as string)
        .replace('{USER_FIRST_NAME}', ((userAttributes?.givenName as string)?.trim()) || 'null')
        .replace('{USER_LAST_NAME}', ((userAttributes?.familyName  as string)?.trim() ) || 'null')
        .replace('{USER_EMAIL}', encodeURIComponent(userAttributes?.email as string));
};

export default addUrlSubstitutions;
