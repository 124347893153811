import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { CourseProductLesson } from '../../../types/course-product.ts';
import useContent from '../../../hooks/useContent.tsx';
import useUserAccess from '../../../hooks/useUserAccess.tsx';
import { ExclamationTriangleIcon, PlayCircleIcon } from '@heroicons/react/24/outline';
import NoAccessPage from '../../../pages/no-access';
import { orderBy } from 'lodash';
import { useCreatorConfig } from '../../../providers/ConfigProvider';
import { useMutation, useQuery } from '@apollo/client';
import getVdoCipherVideoTokenQuery from '../../../api/content/getVdoCipherVideoTokenQuery.graphql';
import moment from 'moment';
import trackUserContentAnalyticsMutation from '../../../api/content-analytics/trackUserContentAnalyticsMutation.graphql';
import getUserContentAnalyticsQuery from '../../../api/content-analytics/getUserContentAnalyticsQuery.graphql';
import AssetItem from '../../../components/AssetItem';
import classNames from '../../../utils/classNames.ts';
import PageLoader from '../../_pages/PageLoader';
import Loader from '../../_base/Loader';
import Button from '../../_base/Button';
import Pill from '../../_base/Pill';
import VideoBrowserSupportModal from '../../VideoBrowserSupportModal';
import PrimaryText from '../../_base/PrimaryText';
import ThemedSelect from '../../_base/ThemedSelect';
import useContentAnalytics from '../../../hooks/useContentAnalytics.tsx';

const CourseModule = ({ className, ...props }: { className?: string }) => {
    const { courseId, moduleId, lessonId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [isBrowserSupportModalOpen, setIsBrowserSupportModalOpen] = useState(false);
    const playerAnalytics = useRef({ currentTime: 0 });
    const iframeRef = useRef(null);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const { vendorUuid, theme } = useCreatorConfig();
    const { hasAccess } = useUserAccess({ contentId: courseId });
    const { data, isLoading } = useContent({ type: 'course', productId: courseId! });
    const { data: contentAnalytics, refetch: refetchContentAnalytics } = useContentAnalytics({
        productId: courseId!,
    });
    const [currentLesson, setCurrentLesson] = useState(null as CourseProductLesson | null);

    const modules = orderBy(data?.sections, ['order'], ['asc']);
    const module = modules?.find(({ id }: { id: string }) => id === moduleId)!;
    const modulesSelectOptions = modules?.map(({ id, name: label, lessons }) => ({
        label,
        value: id,
        firstLessonId: orderBy(lessons, ['order'], ['asc'])?.[0]?.id,
    }));

    const [trackUserContentAnalytics] = useMutation(trackUserContentAnalyticsMutation);
    const { refetch } = useQuery(getUserContentAnalyticsQuery, {
        variables: {
            vendorUuid,
            productId: courseId,
        },
        skip: !vendorUuid || !courseId,
    });

    useEffect(() => {
        if (refetchContentAnalytics) refetchContentAnalytics();
    }, [currentLesson]);

    const lessonsScrollRef = useRef({} as Record<string, HTMLAnchorElement | null>);
    useEffect(() => {
        if (currentLesson && !!lessonsScrollRef?.current?.[currentLesson.id] && window.innerWidth > 1023)
            setTimeout(() => {
                lessonsScrollRef?.current?.[currentLesson.id]?.scrollIntoView({ behavior: 'smooth' });
            }, 250);
    }, [currentLesson]);
    useEffect(() => {
        window?.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location.pathname]);

    useEffect(() => {
        if (data && !isLoading && module) {
            const selectedLesson = modules
                ?.reduce((acc, { lessons }) => [...acc, ...lessons], [] as CourseProductLesson[])
                .find(({ id }) => id === lessonId);

            if (selectedLesson) setCurrentLesson(selectedLesson);
            else
                navigate(
                    `/courses/${courseId}/modules/${module.id}/lessons/${orderBy(
                        module?.lessons,
                        ['order'],
                        ['asc'],
                    )?.[0].id}`,
                    {
                        replace: true,
                    },
                );
        }
    }, [modules, isLoading, location]);

    const videoId = currentLesson?.assets?.find(({ asset_type }) => asset_type.name === 'Secure Video')?.sprout_id;
    const { data: videoTokenData } = useQuery(getVdoCipherVideoTokenQuery, {
        variables: { vendorUuid, productId: courseId, videoId },
        skip: !videoId || !videoId || !courseId,
        fetchPolicy: 'network-only',
    });

    const { data: videoToken } = videoTokenData?.getVdoCipherVideoToken ?? {};

    const lessons = orderBy(module?.lessons, ['order'], ['asc']);

    const getVdoPlayer = () => {
        const iframe = document.querySelector('iframe');
        if (iframe) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const player = VdoPlayer?.getInstance(iframe);
            if (player) return player;
        }

        return null;
    };
    const timeUpdateEventListener = () => {
        const player = getVdoPlayer();
        if (player) playerAnalytics.current.currentTime = player.video.currentTime;
    };
    const onPlayListener = () => setIsVideoPlaying(true);
    const onPauseListener = () => {
        setIsVideoPlaying(false);
        if (refetchContentAnalytics) refetchContentAnalytics();
    };

    const initVideoListeners = () => {
        const player = getVdoPlayer();
        if (player) {
            player.video.addEventListener('play', onPlayListener);
            player.video.addEventListener('pause', onPauseListener);
            player.video.addEventListener('timeupdate', timeUpdateEventListener);
        }
    };

    useEffect(() => {
        initVideoListeners();
    }, [location]);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            const player = getVdoPlayer();
            if (isVideoPlaying && player)
                await trackUserContentAnalytics({
                    variables: {
                        vendorUuid,
                        productId: courseId,
                        contentId: lessonId,
                        data: {
                            lastVideoPlayTimeSeconds: player?.video?.currentTime
                                ? Math.floor(player?.video?.currentTime as number)
                                : 0,
                        },
                    },
                });
        }, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, [isVideoPlaying, playerAnalytics]);

    useEffect(() => {
        // Remove listeners
        return () => {
            const player = getVdoPlayer();
            if (player) {
                player.video.removeEventListener('play', onPlayListener);
                player.video.removeEventListener('pause', onPauseListener);
                player.video.removeEventListener('timeupdate', timeUpdateEventListener);
            }
            refetch(); // We refetch course analytics as it has been updated via trackUserContentAnalytics
        };
    }, []);

    const fileAssets = currentLesson?.assets?.filter(({ asset_type }) => asset_type.name === 'File');

    return (
        <div className={classNames(className)} {...props}>
            <PageLoader inline isLoading={isLoading}>
                <div className="w-full flex flex-col lg:flex-row gap-8 lg:gap-4">
                    <div className="flex-1">
                        <div>
                            <div className="overflow-hidden relative w-full h-full aspect-video bg-black rounded-xl">
                                {hasAccess && !videoToken && (
                                    <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center">
                                        <Loader />
                                    </div>
                                )}
                                {hasAccess && videoToken && (
                                    <iframe
                                        key={location?.pathname}
                                        src={`https://player1.vdocipher.com/v2/?videoId=${videoId}&token=${videoToken}`}
                                        ref={iframeRef}
                                        onLoad={initVideoListeners}
                                        style={{
                                            border: 0,
                                            maxWidth: '100%',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            height: '100%',
                                            width: '100%',
                                        }}
                                        allowFullScreen={true}
                                        allow="encrypted-media"
                                    />
                                )}
                                {hasAccess === false && <NoAccessPage />}
                            </div>
                        </div>

                        <div className="mt-2 flex flex-row justify-end">
                            <Button size="xs" onClick={() => setIsBrowserSupportModalOpen(true)}>
                                <PrimaryText className="flex flex-row items-center gap-2">
                                    <ExclamationTriangleIcon className="h-4 w-4" /> Video issues?
                                </PrimaryText>
                            </Button>
                        </div>
                        <PrimaryText inlineBlock className="text-xl font-bold mt-4 lg:mt-0">
                            {currentLesson?.name}
                        </PrimaryText>
                        <PrimaryText
                            block
                            className="text-base mt-2"
                            dangerouslySetInnerHTML={{
                                __html:
                                    currentLesson?.assets?.find(({ asset_type }) => asset_type.name === 'Secure Video')
                                        ?.description ?? '',
                            }}
                        />
                        {(fileAssets?.length ?? 0) > 0 && (
                            <>
                                <PrimaryText block className="text-xl font-semibold mt-4">
                                    Materials
                                </PrimaryText>
                                <div className="flex items-center gap-4 flex-wrap mt-2">
                                    {fileAssets?.map(({ title, url, asset_type }) => (
                                        <AssetItem asset={{ assetType: asset_type, title, url }} />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="lg:w-[350px] flex flex-col gap-4">
                        {modulesSelectOptions?.length > 1 ? (
                            <ThemedSelect
                                key={module?.id}
                                defaultValue={module && { value: module.id, label: module.name }}
                                onChange={(e) => {
                                    const { value, firstLessonId } = e as unknown as {
                                        value: string;
                                        firstLessonId: string | undefined;
                                    };
                                    navigate(
                                        `/courses/${courseId}/modules/${value}${
                                            firstLessonId ? `/lessons/${firstLessonId}` : ''
                                        }`,
                                        {
                                            replace: true,
                                        },
                                    );
                                }}
                                options={modulesSelectOptions}
                                isSearchable
                                classNames={
                                    {
                                        // container: () => '!w-64',
                                    }
                                }
                                placeholder="Select module..."
                            />
                        ) : (
                            <PrimaryText className="font-bold">{module?.name}</PrimaryText>
                        )}
                        <div className="flex flex-col gap-4 flex-1 lg:max-h-[80vh] pr-3 overflow-y-scroll">
                            {lessons?.map((lesson) => {
                                const videoAsset = lesson?.assets?.find(
                                    ({ asset_type }) => asset_type.name === 'Secure Video',
                                );

                                const lessonContentAnalytics = contentAnalytics?.find(
                                    ({ contentId }) => contentId === lesson.id,
                                );

                                const percentageComplete =
                                    videoAsset?.length_seconds! > 0 &&
                                    lessonContentAnalytics?.lastVideoPlayTimeSeconds! > 0
                                        ? (lessonContentAnalytics?.lastVideoPlayTimeSeconds! * 100) /
                                          videoAsset?.length_seconds!
                                        : 0;
                                return (
                                    <Link
                                        key={lesson.id}
                                        ref={(ref) => (lessonsScrollRef.current[lesson.id] = ref)}
                                        replace={true}
                                        to={`/courses/${courseId}/modules/${moduleId}/lessons/${lesson.id}`}
                                        className="flex flex-row items-center cursor-pointer gap-2 p-2 rounded-xl border hover:lg:-translate-y-2 lg:duration-300 lg:ease-in-out"
                                        style={{
                                            backgroundColor: theme?.colours?.secondaryColour,
                                            borderColor:
                                                lesson.id === currentLesson?.id
                                                    ? theme?.colours?.primaryColour
                                                    : theme?.colours?.secondaryColour,
                                        }}>
                                        <div
                                            className="aspect-video w-[150px] rounded-xl overflow-hidden relative border"
                                            style={{
                                                borderColor: theme?.colours?.secondaryColour,
                                            }}>
                                            <img
                                                src={videoAsset?.thumbnail}
                                                className="object-cover object-center absolute"
                                            />
                                            <PlayCircleIcon className="h-8 w-8 absolute text-gray-100 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                                            {percentageComplete > 0 && (
                                                <div
                                                    className="absolute bottom-0 left-0 right-0 h-1"
                                                    style={{
                                                        backgroundColor: theme?.colours?.secondaryColour,
                                                    }}>
                                                    <div
                                                        className="h-full"
                                                        style={{
                                                            backgroundColor: theme?.colours?.primaryColour,
                                                            width: `${percentageComplete}%`,
                                                        }}></div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex-1 text-sm flex flex-col gap-2">
                                            <PrimaryText
                                                isSelected={lesson.id === currentLesson?.id}
                                                className="text-sm font-semibold">
                                                {lesson.name}
                                            </PrimaryText>
                                            <div className="flex flex-row">
                                                <Pill
                                                    label={
                                                        videoAsset?.length_seconds
                                                            ? moment
                                                                  .utc(videoAsset?.length_seconds * 1000)
                                                                  .format('HH:mm:ss')
                                                            : ''
                                                    }
                                                    style={
                                                        currentLesson?.id === lesson.id
                                                            ? {
                                                                  backgroundColor: theme?.colours?.primaryColour,
                                                                  color: theme?.colours?.textOnPrimaryColour,
                                                              }
                                                            : {}
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <VideoBrowserSupportModal
                    isOpen={isBrowserSupportModalOpen}
                    onClose={() => setIsBrowserSupportModalOpen(false)}
                />
            </PageLoader>
        </div>
    );
};

export default CourseModule;
